.PassingMiniGame {
  background-color: rgb(58, 29, 1);

  .container {
    text-align: center;
    line-height: 0; // Add this to avoid the extra height compared to the inline-block content of this container
    padding: 0;
    background-color: rgb(58, 29, 1);
  }

  .grass {
    display: inline-block;
    background-color: rgb(255, 255, 255);

    width: calc(#{$contentHeight} / 1.57);
    height: $contentHeight;

    // padding: 2% 4%;
  }

  .playingField {
    width: 100%;
    height: 100%;
    background-color: rgb(24, 163, 54);
  }
}
