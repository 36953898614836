.EspionageMiniGame {
  background-color: white;
  .row {
      margin-top: 1.8em;
  }
  .symbol-button {
    width: 100%;
    // height: 0;
    // padding-bottom: 100%;

    font-size: 24px;
    @include media-breakpoint-up(sm) {
        font-size: 48px;
    }

    @include media-breakpoint-up(md) {
        font-size: 72px;
    }
  }
}
