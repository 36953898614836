.Header {
  background-color: rgb(14, 14, 22);
  height: $headerHeight;
  border-bottom: solid 0.2vh rgb(47, 47, 53);
  font-size: 2.5vh;
  padding-top: 1.2vh;
  font-family: Monaco, monospace;
  color: #666666;
  user-select: none;

  .team-time {
    &.current {
      color: #ffffff;
      &.warning {
        color: #bb0000;
      }
    }
  }

  .team-name {
    margin: 0 0.8em;

    &.current {
      color: #ffffff;
    }
  }

  .score {
    color: #ffffff;
    margin: 0 0.5em;
  }

  .warnings {
    display: inline-block;
    width: 1em;
    img {
      max-width: 100%;
    }
  }
}
