.DribblingMiniGame {
  background-color: white;

  .game-area {
    display: inline-block;
    width: 50vh;
    height: 50vh;
    margin-top: 3vh;

    .game-board {
      width: 100%;
      height: 100%;

      .control-arrow {
        fill: rgb(58, 29, 1);
        stroke: none;

        &:hover {
          fill: rgb(133, 71, 13);
        }
      }
    }
  }
}
