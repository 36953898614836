.App {
  text-align: center;
  //   line-height: 0; // Add this to avoid the extra height compared to the inline-block content of this container
  padding: 0;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .main-menu-content {
    position: relative;
    top: 15vh;

    h1 {
      font-size: 320%;
    }

    img {
      height: 20vh;
    }

    .title {
      margin: 1.5em 0;
    }

    .time-per-team-input-group {
      position: fixed;
      bottom: 5vh;
      width: 100%;
      label {
        margin: 0 0.5em;
      }
      input {
        width: 2.5em;
        text-align: center;

        &.invalid {
          border: solid 1px red;
        }
      }
    }

    .manual {
        position: fixed;
        bottom: 1em;
        right: 1em;
    }
  }

  .changing-content {
    .title {
      margin: 4em 0;
    }
  }

  .notification-panel {
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .card {
      border: solid 0.2vh #496d6d;
      background-color: #79a5a5;
      width: 50vw;
      height: 40vh;
      margin-top: 20vh;
      padding-top: 5vh;

      .card-body {
        padding: 0.5em;

        button {
          margin-top: 2em;
        }
      }

      img {
        height: 30vh;
      }
    }
  }
}

.game-name {
  position: fixed;
  top: $headerHeight;
  right: 1em;
  color: black;
  &.light {
      color: white;
  }
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
